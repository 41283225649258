import moment from 'moment'
import _ from 'underscore'
import { currencyFormatter } from '../../../../utils/formatter'

export function visitasDashboard(data) {
  const { userFarms, visits } = data
  const dataset = []

  userFarms.forEach(item => {
    let visitsForMonth = {}

    visits.forEach(visit => {
      if (visit.farmId !== item.fazendaId) return

      const month = moment(visit.dataAtendimento, 'DD/MM/YYYY')
        .format('MMM')
        .toLowerCase()

      if (!month) return

      if (!visitsForMonth[month]) {
        visitsForMonth[month] = 0
      }

      visitsForMonth[month] += 1
    })

    visitsForMonth['total'] = Object.values(visitsForMonth).reduce(
      (acc, curr) => acc + curr,
      0,
    )

    dataset.push({
      label: item.fazenda.pessoajuridica.NomeFantasia,
      produtor: item.fazenda.produtor.Nome,
      ...visitsForMonth,
    })

    visitsForMonth = {}
  })

  const totalVisitsByMonths = {}

  dataset.forEach(item => {
    Object.keys(item).forEach(key => {
      if (key === 'label' || key === 'produtor') return
      if (!totalVisitsByMonths[key]) {
        totalVisitsByMonths[key] = 0
      }

      totalVisitsByMonths[key] += item[key]
    })
  })

  const result = previsionVisitsByMonths(data)

  result['total'] = Object.values(result).reduce((acc, curr) => acc + curr, 0)

  dataset.push({
    label: 'Total de atendimentos previstos',
    class: 'blue-row',
    ...result,
  })

  dataset.push({
    ...totalVisitsByMonths,
    label: 'Total de propriedades atendidas',
    class: 'blue-row',
  })

  const { totalDespesasByMonth } = despesas(data)

  const costPerVisit = {}

  Object.keys(totalDespesasByMonth).forEach(key => {
    if (!totalVisitsByMonths[key]) return

    costPerVisit[key] = totalDespesasByMonth[key] / totalVisitsByMonths[key]
  })

  formatToCurrency(totalDespesasByMonth)

  dataset.push({
    ...totalDespesasByMonth,
    label: 'Custo total',
    class: 'blue-row',
  })

  formatToCurrency(costPerVisit)

  dataset.push({
    ...costPerVisit,
    label: 'Custo por visita',
    class: 'blue-row',
  })

  return dataset
}

export function headers(
  label,
  startDate,
  endDate,
  year,
  endLabel = 'Total',
  others = [],
) {
  const headers = []
  const yearsLabels = []
  const abreviatedYear = moment(`${year}-12-12`).format('YY')

  let start = moment(startDate)
  let end = moment(endDate)

  if (year < Number(end.format('YYYY'))) {
    end = moment(`${year}-12-31`)
  } else if (year > Number(start.format('YYYY'))) {
    start = moment(`${year}-01-01`)
  }

  while (start.isBefore(end) || start.isSame(end)) {
    const month = start.format('MMM').toLowerCase()

    yearsLabels.push({
      text: `${month}/${abreviatedYear}`,
      sortable: false,
      value: month,
      align: 'start',
      width: '50px',
    })

    start.add(1, 'month')
  }

  headers.push({
    text: label,
    sortable: false,
    value: 'label',
    align: 'start',
    width: `${label.length * 10}px`,
  })

  if (others.length) {
    others.forEach(item => {
      headers.push({
        text: item.text,
        sortable: false,
        value: item.value,
        align: 'start',
        width: item.width,
      })
    })
  }

  headers.push(...yearsLabels)

  headers.push({
    text: endLabel,
    sortable: false,
    width: '15px',
    value: 'total',
    align: 'start',
  })

  return headers
}

export function depesasDashboard(data) {
  const {
    despesasAlimentacao,
    despesasCombustivel,
    despesasPedagio,
    despesasHospedagem,
    despesasFees,
    despesasHealthInsurance,
    despesasLifeInsurance,
    despesasOtherValues,
    totalDespesasByMonth,
  } = despesas(data)

  // format to currency
  formatToCurrency(despesasAlimentacao)
  formatToCurrency(despesasCombustivel)
  formatToCurrency(despesasPedagio)
  formatToCurrency(despesasHospedagem)
  formatToCurrency(despesasFees)
  formatToCurrency(despesasHealthInsurance)
  formatToCurrency(despesasLifeInsurance)
  formatToCurrency(despesasOtherValues)
  formatToCurrency(totalDespesasByMonth)

  const dataset = [
    {
      label: 'Alimentação',
      ...despesasAlimentacao,
    },
    {
      label: 'Combustível',
      ...despesasCombustivel,
    },
    {
      label: 'Pedágio',
      ...despesasPedagio,
    },
    {
      label: 'Hospedagem',
      ...despesasHospedagem,
    },
    {
      label: 'Honorários',
      ...despesasFees,
    },
    {
      label: 'Plano de Saúde',
      ...despesasHealthInsurance,
    },
    {
      label: 'Seguro de Vida ',
      ...despesasLifeInsurance,
    },
    {
      label: 'Outros valores',
      ...despesasOtherValues,
    },
    {
      label: 'Total',
      ...totalDespesasByMonth,
      class: 'blue-row',
    },
  ]

  return dataset
}

export function eficienciaDashboard(data) {
  const despesas = {}
  const visitResult = visitasDashboard(data)

  data.finalcialRelease.forEach(item => {
    const month = moment(item.launch).format('MMM').toLowerCase()

    if (!despesas[month]) {
      despesas[month] = 0
    }

    despesas[month] += item.alimentacao
    despesas[month] += item.combustivel
    despesas[month] += item.pedagio
    despesas[month] += item.hospedagem
    despesas[month] += item.fees
    despesas[month] += item.healthInsurance
    despesas[month] += item.lifeInsurance
    despesas[month] += item.otherValues
  })

  Object.keys(despesas).forEach(key => {
    if (!visitResult[visitResult.length - 4][key]) return
    despesas[key] = despesas[key] / visitResult[visitResult.length - 4][key]
  })

  despesas['total'] = Object.values(despesas).reduce(
    (acc, curr) => acc + curr,
    0,
  )

  // format to currency
  formatToCurrency(despesas)

  const datasetVisit = []

  const result = previsionVisitsByMonths(data)

  data.userFarms.forEach(item => {
    let visitsForMonth = {}

    data.visits.forEach(visit => {
      if (visit.farmId !== item.fazendaId) return

      const month = moment(visit.dataAtendimento, 'DD/MM/YYYY')
        .format('MMM')
        .toLowerCase()

      if (!month) return

      if (!visitsForMonth[month]) {
        visitsForMonth[month] = 0
      }

      visitsForMonth[month] += 1
    })

    datasetVisit.push({
      ...visitsForMonth,
    })

    visitsForMonth = {}
  })

  const totalVisitsByMonths = {}

  datasetVisit.forEach(item => {
    Object.keys(item).forEach(key => {
      if (!totalVisitsByMonths[key]) {
        totalVisitsByMonths[key] = 0
      }

      totalVisitsByMonths[key] += item[key]
    })
  })

  const visitasRealizadas = {}

  Object.keys(totalVisitsByMonths).forEach(key => {
    if (!result[key]) return
    visitasRealizadas[key] = (totalVisitsByMonths[key] * 100) / result[key]
  })

  const avgVisitsRelizadas =
    Object.values(visitasRealizadas).reduce((acc, curr) => acc + curr, 0) /
    Object.values(visitasRealizadas).length

  visitasRealizadas['total'] = avgVisitsRelizadas

  // format to percent
  Object.keys(visitasRealizadas).forEach(key => {
    visitasRealizadas[key] = visitasRealizadas[key]
      ? visitasRealizadas[key].toFixed(2) + '%'
      : ' '
  })

  let metas = data.metas.map(item => {
    const month = moment(item.dataDeReferencia).format('MMM').toLowerCase()

    return {
      [month]: currencyFormatter(item.meta, 'R$'),
    }
  })

  metas = metas.reduce((acc, curr) => {
    return {
      ...acc,
      ...curr,
    }
  }, {})

  const dataset = [
    {
      label: 'Custo por visita',
      ...despesas,
    },
    {
      label: 'Custo por visita mensal',
      ...metas,
    },
    {
      label: '% visitas realizadas',
      ...visitasRealizadas,
    },
  ]

  return dataset
}

export function formatToCurrency(object) {
  Object.keys(object).forEach(key => {
    object[key] = object[key].toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
  })
}

export function previsionVisitsByMonths(data) {
  const start = moment().startOf('year')
  const previsionVisitsByMonths = {}

  while (start.isBefore(moment().endOf('year'))) {
    const month = start.format('MMM').toLowerCase()

    if (!previsionVisitsByMonths[month]) {
      previsionVisitsByMonths[month] = 0
    }

    const visits = data.userFarms.filter(item => {
      const joinDate = moment(item.joinDate, 'YYYY-MM-DD')
      const leaveDate = moment(item.leaveDate, 'YYYY-MM-DD')

      start.endOf('month')

      if (joinDate.isAfter(start)) return false

      return !(leaveDate.isValid() && leaveDate.isBefore(start))
    })

    previsionVisitsByMonths[month] += visits.length

    start.add(1, 'month')
  }

  return previsionVisitsByMonths
}

function despesas(data) {
  const { finalcialRelease } = data

  const despesasAlimentacao = {}
  const despesasCombustivel = {}
  const despesasPedagio = {}
  const despesasHospedagem = {}
  const despesasFees = {}
  const despesasHealthInsurance = {}
  const despesasLifeInsurance = {}
  const despesasOtherValues = {}

  finalcialRelease.forEach(item => {
    const month = moment(item.launch).format('MMM').toLowerCase()

    if (!despesasAlimentacao[month]) {
      despesasAlimentacao[month] = 0
    }

    if (!despesasCombustivel[month]) {
      despesasCombustivel[month] = 0
    }

    if (!despesasPedagio[month]) {
      despesasPedagio[month] = 0
    }

    if (!despesasHospedagem[month]) {
      despesasHospedagem[month] = 0
    }

    if (!despesasFees[month]) {
      despesasFees[month] = 0
    }

    if (!despesasHealthInsurance[month]) {
      despesasHealthInsurance[month] = 0
    }

    if (!despesasLifeInsurance[month]) {
      despesasLifeInsurance[month] = 0
    }

    if (!despesasOtherValues[month]) {
      despesasOtherValues[month] = 0
    }

    despesasAlimentacao[month] += item.alimentacao
    despesasCombustivel[month] += item.combustivel
    despesasPedagio[month] += item.pedagio
    despesasHospedagem[month] += item.hospedagem
    despesasFees[month] += item.fees
    despesasHealthInsurance[month] += item.healthInsurance
    despesasLifeInsurance[month] += item.lifeInsurance
    despesasOtherValues[month] += item.otherValues
  })

  despesasAlimentacao['total'] = Object.values(despesasAlimentacao).reduce(
    (acc, curr) => acc + curr,
    0,
  )

  despesasCombustivel['total'] = Object.values(despesasCombustivel).reduce(
    (acc, curr) => acc + curr,
    0,
  )

  despesasPedagio['total'] = Object.values(despesasPedagio).reduce(
    (acc, curr) => acc + curr,
    0,
  )

  despesasHospedagem['total'] = Object.values(despesasHospedagem).reduce(
    (acc, curr) => acc + curr,
    0,
  )

  despesasFees['total'] = Object.values(despesasFees).reduce(
    (acc, curr) => acc + curr,
    0,
  )

  despesasHealthInsurance['total'] = Object.values(
    despesasHealthInsurance,
  ).reduce((acc, curr) => acc + curr, 0)

  despesasLifeInsurance['total'] = Object.values(despesasLifeInsurance).reduce(
    (acc, curr) => acc + curr,
    0,
  )

  despesasOtherValues['total'] = Object.values(despesasOtherValues).reduce(
    (acc, curr) => acc + curr,
    0,
  )

  const totalDespesasByMonth = {}

  Object.keys(despesasAlimentacao).forEach(key => {
    if (!totalDespesasByMonth[key]) {
      totalDespesasByMonth[key] = 0
    }

    totalDespesasByMonth[key] += despesasAlimentacao[key]
  })

  Object.keys(despesasCombustivel).forEach(key => {
    if (!totalDespesasByMonth[key]) {
      totalDespesasByMonth[key] = 0
    }

    totalDespesasByMonth[key] += despesasCombustivel[key]
  })

  Object.keys(despesasPedagio).forEach(key => {
    if (!totalDespesasByMonth[key]) {
      totalDespesasByMonth[key] = 0
    }

    totalDespesasByMonth[key] += despesasPedagio[key]
  })

  Object.keys(despesasHospedagem).forEach(key => {
    if (!totalDespesasByMonth[key]) {
      totalDespesasByMonth[key] = 0
    }

    totalDespesasByMonth[key] += despesasHospedagem[key]
  })

  Object.keys(despesasFees).forEach(key => {
    if (!totalDespesasByMonth[key]) {
      totalDespesasByMonth[key] = 0
    }

    totalDespesasByMonth[key] += despesasFees[key]
  })

  Object.keys(despesasHealthInsurance).forEach(key => {
    if (!totalDespesasByMonth[key]) {
      totalDespesasByMonth[key] = 0
    }

    totalDespesasByMonth[key] += despesasHealthInsurance[key]
  })

  Object.keys(despesasLifeInsurance).forEach(key => {
    if (!totalDespesasByMonth[key]) {
      totalDespesasByMonth[key] = 0
    }

    totalDespesasByMonth[key] += despesasLifeInsurance[key]
  })

  Object.keys(despesasOtherValues).forEach(key => {
    if (!totalDespesasByMonth[key]) {
      totalDespesasByMonth[key] = 0
    }

    totalDespesasByMonth[key] += despesasOtherValues[key]
  })

  return {
    despesasAlimentacao,
    despesasCombustivel,
    despesasPedagio,
    despesasHospedagem,
    despesasFees,
    despesasHealthInsurance,
    despesasLifeInsurance,
    despesasOtherValues,
    totalDespesasByMonth,
  }
}

export function groupVisits(data) {
  let result = _.groupBy(
    data.visits.reduce((acc, curr) => acc.concat(curr), []),
    ({ dataAtendimento }) =>
      moment(dataAtendimento, 'DD/MM/YYYY').format('YYYY'),
  )

  const finalcialRelease = _.groupBy(data.finalcialRelease, ({ launch }) =>
    moment(launch).format('YYYY'),
  )

  return {
    userFarms: data.userFarms,
    visits: result,
    finalcialRelease,
    metas: data.metas,
  }
}
