<template>
  <base-loading v-if="loading" />
  <div v-else class="flex">
    <DashboardCoast
      v-if="response.costVisit"
      :data="response.costVisit"
      :start-date="form.dataInicio"
      :end-date="form.dataFim"
    />
    <v-row align="center" justify="center">
      <v-col
        v-for="(item, index) in dashboardData"
        :key="item.report_id"
        :v-if="dashboardData && dashboardData.length >= 1"
        cols="10"
      >
        <v-card class="pa-md-8">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            {{ item.title }}
          </h2>
          <base-table-graph
            :items="consultantTableData(data[index])"
            :headers="headerConsultantTable"
            :custom-sort="customSort"
          />
          <base-bar-graph
            :chart-data="item.data"
            :style="styles"
            :chart-options="options"
          />
        </v-card>
      </v-col>

      <v-col
        v-if="dashboardData.length >= 1"
        cols="4"
        align="center"
        justify="center"
      >
        <v-btn
          style="color: white; border-radius: 8px"
          color="primary"
          @click="generateConsultorReport(data, form)"
        >
          <span style="font-size: 16px; font-weight: 600"
            ><v-icon style="margin-right: 6px">mdi-file-excel</v-icon>DOWNLOAD
            EXCEL</span
          >
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ENV_APP } from '../../../../env'
import api from '../../../api/api'
import { generateConsultorReport } from '../../../services/spreadsheet/ConsultorReport'
import {
  consultantDatasetGraph,
  consultantTableData,
  headerConsultantTable,
  renderConsultantDatasetGraph,
} from '../../../utils/dashboard/consultantDashboard'
import DashboardCoast from './CostDashboard/Dashboard.vue'

export default {
  name: 'Dashboard',

  components: {
    DashboardCoast,
  },

  props: {
    form: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      headerConsultantTable,
      loading: false,

      options: {
        indexAxis: 'y',
        barPercentage: 0.5,
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              barThickness: 6,
              maxBarThickness: 8,
            },
          ],
        },
      },

      config: {
        indexAxis: 'y',

        elements: {
          bar: {
            borderWidth: 1,
          },
        },

        plugins: {
          legend: {},
          title: {
            display: true,
          },
        },
      },

      dashboardData: [],
      data: [],
      response: {},
      forms: null,

      styles: {
        backgroundColor: '#f1f1f1',
      },
    }
  },

  created() {},

  methods: {
    renderConsultantDatasetGraph,
    consultantDatasetGraph,
    generateConsultorReport,
    consultantTableData,

    getDashboard(form) {
      this.forms = form
      this.loading = true
      api.get
        .consultantDashboard({
          projectId: ENV_APP.ProjectID,
          id: form.consultor,
          DataInicio: form.dataInicio,
          DataFim: form.dataFim,
        })
        .then(response => {
          this.response = response.data
          let data = renderConsultantDatasetGraph(response.data.visit)

          data = data.sort((prev, next) => {
            return prev.report_id - next.report_id
          })

          this.data = data
          this.dashboardData = data.map(item => consultantDatasetGraph(item))
          this.loading = false
          // this.form.consultor = ''
        })
        .catch(error => {
          this.Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message,
          })
          this.loading = false
          this.dashboardData = []
          this.data = []
          this.forms = {}
          this.form.consultor = ''
        })
    },

    customSort(items, sortBy, sortDesc) {
      return items.sort((a, b) => {
        const sortA = a[sortBy]
        const sortB = b[sortBy]

        if (a['agroindustria'] == 'Total') return 1

        if (sortBy == 'agroindustria') {
          if (sortDesc) return sortB.localeCompare(sortA)
          return sortA.localeCompare(sortB)
        }

        if (sortDesc[0]) return sortB - sortA

        return sortA - sortB
      })
    },
  },
}
</script>
