<template>
  <base-loading v-if="loading" />
  <div v-else class="flex">
    <v-row
      v-for="(dash, index) in dashboards"
      :key="index"
      align="center"
      justify="center"
    >
      <v-col cols="10">
        <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
          Visitas realizadas
        </h2>
        <div v-if="dash.dessertsVisitas" class="content">
          <TableGraph
            :items="dash.dessertsVisitas"
            :headers="renderHeaders('visitas', dash.year)"
            class="elevation-1"
            :disable-pagination="false"
            :hide-defaultfooter="false"
          />
        </div>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="10">
        <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
          Despesas por consultor
        </h2>
        <div v-if="dash.dessertsDepesas" class="content">
          <TableGraph
            :items="dash.dessertsDepesas"
            :headers="renderHeaders('despesas', dash.year)"
            class="elevation-1"
            :disable-pagination="false"
            :hide-defaultfooter="false"
          />
        </div>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="10">
        <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
          Indicadores de eficiencia
        </h2>
        <div v-if="dash.dessertsEficiencia" class="content">
          <TableGraph
            :items="dash.dessertsEficiencia"
            :headers="renderHeaders('eficiencia', dash.year)"
            class="elevation-1"
            :disable-pagination="false"
            :hide-defaultfooter="false"
          />
        </div>
        <v-divider :thickness="2"></v-divider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import TableGraph from '../../../../components/base/TableGraph.vue'
import { getYearsBetween } from '../../../../utils/extra'
import {
  depesasDashboard,
  eficienciaDashboard,
  groupVisits,
  headers,
  visitasDashboard,
} from './costDashboard'
export default {
  name: 'CoastDashboard',

  components: { TableGraph },

  props: {
    data: {
      type: Object,
      required: true,
    },

    startDate: {
      type: String,
      required: true,
    },

    endDate: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,

      start: '',
      end: '',

      dashboards: [],
    }
  },

  created() {
    this.dashboard()

    this.start = this.startDate
    this.end = this.endDate
  },

  methods: {
    getYearsBetween,
    dashboard() {
      const result = groupVisits(this.data)

      const years = this.getYearsBetween(
        moment(this.startDate).format('YYYY'),
        moment(this.endDate).format('YYYY'),
      )

      years.forEach(key => {
        const value = result.visits[key]

        this.dashboards.push({
          year: key,
          dessertsVisitas: visitasDashboard({
            visits: value ?? [],
            userFarms: result.userFarms ?? [],
            finalcialRelease: result.finalcialRelease[key] ?? [],
          }),
          dessertsDepesas: depesasDashboard({
            visits: value ?? [],
            userFarms: result.userFarms ?? [],
            finalcialRelease: result.finalcialRelease[key] ?? [],
          }),
          dessertsEficiencia: eficienciaDashboard({
            visits: value ?? [],
            userFarms: result.userFarms ?? [],
            finalcialRelease: result.finalcialRelease[key] ?? [],
            metas: result.metas ?? [],
          }),
        })
      })
    },

    renderHeaders(type, year) {
      if (type === 'visitas') {
        const headersVisitas = headers(
          'Visita realizada',
          this.start,
          this.end,
          year,

          'Total',
          [
            {
              text: 'Produtor',
              sortable: false,
              width: '200px',
              value: 'produtor',
              align: 'start',
            },
          ],
        )

        return headersVisitas
      }

      if (type === 'despesas') {
        const headersDepesas = headers(
          'Despesas por consultor',
          this.start,
          this.end,
          year,
          'Total',
        )

        return headersDepesas
      }

      if (type === 'eficiencia') {
        const headersEficiencia = headers(
          'Indicadores de eficiencia',
          this.start,
          this.end,
          year,
          'Total',
        )

        return headersEficiencia
      }
    },
  },
}
</script>
