import moment from 'moment'
import XLSX from 'xlsx-js-style'

export function generateConsultorReport(data, form) {
  const workbook = XLSX.utils.book_new()

  const sheet = []

  const rowEmpty = [
    {
      v: '',
      t: 's',
    },
  ]

  sheet.push({
    'Agroindústria ': '',
    'Visitas Realizadas': '',
    'Meta de Visitas': '',
  })

  data.forEach(item => {
    sheet.push({
      'Agroindústria ': {
        v: item.title,
        t: 's',
        s: {
          font: {
            sz: 14,
            bold: true,
          },
        },
      },
    })
    Object.entries(item.result).forEach(([, value]) => {
      sheet.push({
        'Agroindústria ': value[0].agroindustriaNome,
        'Visitas Realizadas': value.reduce((acc, item) => acc + item.visits, 0),
        'Meta de Visitas': value.length,
      })
    })

    sheet.push(rowEmpty)
    sheet.push(rowEmpty)
  })

  const consultor = [
    {
      v: 'Consultor: ',
      t: 's',
      s: {
        font: {
          sz: 14,
          bold: true,
        },
      },
    },
    {
      v: form.user.Nome,
      t: 's',
      s: {
        alignment: { wrapText: false },
        font: {
          sz: 14,
          bold: true,
        },
      },
    },
  ]

  const worksheet = XLSX.utils.aoa_to_sheet([consultor])
  XLSX.utils.sheet_add_json(worksheet, sheet, { origin: 'A3' })

  let wscols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }]

  worksheet['A3'].s = {
    font: {
      sz: 14,
      bold: true,
    },
  }
  worksheet['B3'].s = {
    font: {
      sz: 14,
      bold: true,
    },
  }
  worksheet['C3'].s = {
    font: {
      sz: 14,
      bold: true,
    },
  }

  worksheet['!cols'] = wscols
  XLSX.utils.book_append_sheet(workbook, worksheet)
  XLSX.utils.sheet_add_aoa(workbook, [rowEmpty], { origin: -1 })

  XLSX.writeFile(
    workbook,
    `dashboard-${moment(new Date()).format('DD/MM/YYYY')}.xlsx`,
  )
}
