<template>
  <div>
    <base-loading v-if="loading.filter" />
    <v-row v-else align="center" justify="center" class="px-10">
      <v-col class="pa-1" cols="2" sm="2" md="2">
        <v-menu
          v-model="menuDataIni"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(form.dataInicio).format('DD/MM/YYYY')"
              label="Data Inicial"
              prepend-icon="mdi-calendar"
              color="teal"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.dataInicio"
            style="margin: 0px"
            color="teal"
            locale="pt-br"
            @input="menuDataIni = false"
          />
        </v-menu>
      </v-col>
      <v-col class="pa-1" cols="2" sm="2" md="2">
        <v-menu
          v-model="menuDataFim"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(form.dataFim).format('DD/MM/YYYY')"
              color="teal"
              label="Data Final"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.dataFim"
            :min="form.dataInicio"
            :max="dataLimite"
            color="teal"
            locale="pt-br"
            @input="menuDataFim = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="form.consultor"
          autocomplete="autocomplete_off_hack_xfr4!k"
          label="Consultor"
          :items="consultores"
          item-text="Nome"
          item-value="id"
          clearable
          :disabled="loading.consultor"
          :loading="loading.consultor"
          required
        />
      </v-col>

      <v-col class="pa-2" cols="2" sm="2" md="2">
        <v-row justify="end" align="center">
          <v-btn
            style="color: white; border-radius: 8px; margin-left: 40%"
            color="green darken-1"
            :disabled="!form.consultor"
            @click="search"
          >
            <span style="font-size: 16px; font-weight: 600">Buscar</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import consultantService from '../../../services/api/consultant'

export default {
  name: 'VisitFilter',

  data() {
    return {
      loading: {
        filter: false,
        consultor: true,
      },

      searching: true,
      dataLimite: this.moment(this.dataInicio).add(0, 'months').toISOString(),
      menuDataIni: false,
      menuDataFim: false,

      form: {
        user: null,
        consultor: null,
        dataInicio: this.moment()
          .subtract(1, 'months')
          .toISOString(true)
          .substr(0, 10),
        dataFim: this.moment().toISOString(true).substr(0, 10),
      },

      consultores: [{ Nome: 'Carregando', id: 0 }],
    }
  },

  created() {
    this.loadConsultores()
  },

  methods: {
    async loadConsultores() {
      this.loading.consultor = true
      this.consultores = await consultantService.getConsultores({
        active: true,
      })

      this.loading.consultor = false
    },

    search() {
      if (!this.form.consultor) {
        this.Swal.fire({
          title: 'Atenção',
          text: 'Selecione um consultor',
          icon: 'warning',
          confirmButtonText: 'Ok',
        })
        return
      }

      this.form.user = this.consultores.find(
        consultor => consultor.id === this.form.consultor,
      )

      this.$emit('search', this.form)
    },
  },
}
</script>
