import _ from 'underscore'
import { colors } from './theme'

export function renderConsultantDatasetGraph(data) {
  const dataGraph = data.map(item => {
    item.result = _.groupBy(item.result, item => item.agroindustria)

    return item
  })

  return dataGraph
}

export function consultantDatasetGraph(data) {
  let result = {
    title: data.title,
    report_id: data.report_id,

    data: {
      labels: [],
      datasets: [
        {
          label: 'Realizado',
          data: [],
          backgroundColor: colors[2],
        },
        {
          label: 'Meta',
          data: [],
          backgroundColor: colors[1],
        },
      ],
    },
  }

  Object.entries(data.result).forEach(([, value]) => {
    result.data.labels.push(value[0].agroindustriaNome)
    result.data.datasets[0].data.push(
      value.reduce((acc, item) => acc + item.visits, 0),
    )
    result.data.datasets[1].data.push(value.length)
  })

  return result
}

export const headerConsultantTable = [
  { text: 'Agroindústria', value: 'agroindustria' },
  { text: 'Realizado', value: 'realizado' },
  { text: 'Meta', value: 'meta' },
]

export function consultantTableData(data) {
  let result = []

  Object.entries(data.result).forEach(([, value]) => {
    result.push({
      agroindustria: value[0].agroindustriaNome,
      realizado: value.reduce((acc, item) => acc + item.visits, 0),
      meta: value.length,
    })
  })

  result.push({
    agroindustria: 'Total',
    realizado: result.reduce((acc, item) => acc + item.realizado, 0),
    meta: result.reduce((acc, item) => acc + item.meta, 0),
  })

  return result
}
