import moment from 'moment'

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export const getYearsBetween = (startYear, endYear) => {
  let start = moment(`${startYear}-01-01`)
  let end = moment(`${endYear}-12-12`)

  const years = []

  while (start.isBefore(end)) {
    const year = start.format('YYYY')

    years.push(year)

    start = moment(`${Number(year) + 1}-01-01`)
  }

  return years
}
