<template>
  <div>
    <v-card>
      <VisitFilter @search="search" />
    </v-card>
    <v-card>
      <Dashboard ref="dashboard" :form="form" />
    </v-card>
  </div>
</template>

<script>
import Dashboard from './dashboard.vue'
import VisitFilter from './filtro'

export default {
  name: 'Visitas',

  components: {
    VisitFilter,
    Dashboard,
  },

  data() {
    return {
      form: null,
    }
  },

  methods: {
    search(form) {
      if (!form) return

      this.form = form
      this.$refs.dashboard.getDashboard(form)
    },
  },
}
</script>
